import { HelpCircle } from '@saleswhale/barnacle/icons';
import cn from 'classnames';
import { ReactNode } from 'react';
import { Tooltip6DS } from '../../SixSenseElements';
import styles from './AttributeItem.module.scss';

interface AttributeItemProps {
  header: ReactNode;
  body?: {
    text: string | number | null;
    leftContent?: ReactNode;
    linkTo?: string;
    rightContent?: ReactNode;
  };
  bodyClassName?: string;
  className?: string;
  testId?: string;
  tooltip?: string;
  tooltipTestId?: string;
}

export default function AttributeItem({
  header,
  body,
  tooltip,
  tooltipTestId = 'AttributeItem__popup',
  testId = 'AttributeItem',
  className = '',
  bodyClassName = '',
}: AttributeItemProps) {
  const t_tooltip = <span data-testid={tooltipTestId}>{tooltip}</span>;
  const itemBodyContent = (
    <div className={styles.Value} data-testid={`${testId}__body`}>
      {body?.leftContent}
      {body?.text ? (
        <span className={cn(styles.ValueText, bodyClassName)} title={body.text.toString()}>
          {body?.text}
        </span>
      ) : (
        '–'
      )}
      {body?.rightContent}
    </div>
  );

  return (
    <div className={`${styles.ItemContainer} ${className}`} data-testid={testId}>
      <div className={styles.Title}>
        <span>{header}</span>
        {tooltip && (
          <Tooltip6DS showArrow overlay={t_tooltip} placement="bottom" trigger="hover">
            <HelpCircle className={styles.PopupIcon} data-testid={`${testId}Icon`} size={13} />
          </Tooltip6DS>
        )}
      </div>
      {body?.linkTo ? (
        <a href="/" rel="noopener noreferrer" target="_blank">
          {itemBodyContent}
        </a>
      ) : (
        itemBodyContent
      )}
    </div>
  );
}
