import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { TableContainer, Spinner } from '..';
import { htmlSanitizer } from '../../../../utils/formatting';
import { TableState } from '../../../../utils/tableUtils';
import TableNoData from '../Table/TableNoData';
import styles from './InfiniteScrollingTable.module.scss';

interface Props {
  loadData: () => void;
  // to handle case where we pass in a fragment or an array of elements
  tableHeaders: JSX.Element | JSX.Element[];
  tableRows: JSX.Element | JSX.Element[];
  tableState: TableState;
  className?: string;
  emptyStateChildren?: React.ReactNode;
  emptyStateDescription?: string;
  emptyStateHeader?: string;
  // to handle case where we pass in a fragment or an array of elements
  loadingDescription?: string;
  loadingHeader?: string;
  shouldContinuoslyFetchPages?: boolean;
  shouldOverflow?: boolean;
  tableClassName?: string;
  testId?: string;
}
export const getColSpanValue = (tableHeaders: Props['tableHeaders']) =>
  Array.isArray(tableHeaders) ? tableHeaders.length : tableHeaders.props.children?.length || 0;

export const checkIfHasData = (tableRows: Props['tableRows']) =>
  Array.isArray(tableRows) ? tableRows.length > 0 : tableRows.props.children?.length > 0 || false;

export const checkIfShouldLoadData = (nextPage: boolean, hasErrors: boolean) =>
  !!nextPage && !hasErrors;

export const checkIfShouldDisplayEndOfResultsBanner = (hasData: boolean, hasNextPage: boolean) =>
  hasData && !hasNextPage;

export function InfiniteScrollingTable({
  loadingDescription,
  shouldOverflow = false,
  emptyStateHeader = 'No data to show',
  emptyStateDescription = '',
  emptyStateChildren,
  loadingHeader = 'One moment...',
  className = '',
  tableClassName = '',
  loadData,
  tableHeaders,
  tableRows,
  tableState,
  testId = 'InfiniteScrollingTable',
  shouldContinuoslyFetchPages,
}: Props) {
  const { isLoading, isFetchingNextPage, hasNextPage, hasErrors } = tableState;
  const { ref: footerRef, inView: isFooterInView } = useInView({ threshold: 0 });
  const shouldLoadData = checkIfShouldLoadData(hasNextPage, hasErrors);
  const colSpanValue = getColSpanValue(tableHeaders);
  const hasData = checkIfHasData(tableRows);
  const displayEndOfResultsBanner = checkIfShouldDisplayEndOfResultsBanner(hasData, hasNextPage);

  useEffect(() => {
    (async () => {
      if (
        shouldLoadData &&
        !isFetchingNextPage &&
        (shouldContinuoslyFetchPages || isFooterInView)
      ) {
        await loadData();
      }
    })();
  }, [shouldLoadData, isFetchingNextPage, isFooterInView, shouldContinuoslyFetchPages, loadData]);

  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      <TableContainer
        className={`${styles.Table} ${tableClassName}`}
        shouldOverflow={shouldOverflow}
      >
        <thead>
          <tr data-testid="InfiniteScrollingTable__tableHeadersContainer">{tableHeaders}</tr>
        </thead>
        {hasData ? (
          <tbody data-testid="InfiniteScrollingTable__bodyWithData">
            {tableRows}
            {isFetchingNextPage && (
              <tr className={styles.Loader} data-testid="InfiniteScrollingTable__tableRowSpinner">
                <td colSpan={colSpanValue}>
                  <div className={styles.SpinnerContainer}>
                    <Spinner className={styles.Spinner} size="medium" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        ) : isLoading ? (
          <tbody data-testid="InfiniteScrollingTable__bodyLoading">
            <tr className={styles.Loader}>
              <td colSpan={colSpanValue}>
                <div className={styles.SpinnerContainer}>
                  <Spinner size="large" />
                  <p className={styles.LoadingHeader}>{loadingHeader}</p>
                  {loadingDescription && (
                    <p
                      className={styles.LoadingDescription}
                      dangerouslySetInnerHTML={{ __html: htmlSanitizer(loadingDescription) }}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <TableNoData
            children={emptyStateChildren}
            colSpan={colSpanValue}
            description={emptyStateDescription}
            title={emptyStateHeader}
          />
        )}
        {!isLoading && (
          <tfoot data-testid={`${testId}__tableFooter`} ref={footerRef}>
            {displayEndOfResultsBanner && (
              <tr>
                <td className={styles.EndOfResultsText} colSpan={colSpanValue}>
                  End of results
                </td>
              </tr>
            )}
          </tfoot>
        )}
      </TableContainer>
    </div>
  );
}
