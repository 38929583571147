import { IFlagsmith } from 'flagsmith/types';
import { useEffect } from 'react';
import { useFeatureManager } from 'services/featureManager';

// we're not really rendering anything here. pretty much sticking to the default render
// behaviour, just with useEffect in action.
export function ErrorFallback({
  _error,
  flagsmith,
}: {
  _error: Error | null;
  flagsmith: IFlagsmith<string, string>;
}) {
  const appUnderMaintenance = useFeatureManager('app_under_maintenance');
  const appIsDown = useFeatureManager('app_is_down');

  useEffect(() => {
    // this checks if app is under maintenance
    const checkUpdatedFlags = async () => {
      try {
        await flagsmith.getFlags();
        (appIsDown || appUnderMaintenance) && window.location.assign('/maintenance');
      } catch (fetchError) {
        console.error('Failed to re-fetch Flagsmith flags:', fetchError);
      }
    };

    checkUpdatedFlags();
  }, [flagsmith, appUnderMaintenance, appIsDown, _error]);

  return null;
}
