import styles from './AppUnderMaintenance.module.scss';

export default function AppUnderMaintenance({ isAppDown }: { isAppDown: boolean }) {
  const maintenanceDetails = {
    title: 'We’re just cleaning up',
    description:
      'This page is unavailable while we’re doing some planned maintenance. Try again in a few minutes.',
  };
  const downDetails = {
    title: 'Looks like we’re having technical difficulties',
    description: `We’re working on the problem as fast as we can. Come back in a little while. If the problem continues, contact support@6sense.com.`,
  };
  const applicableText = isAppDown ? downDetails : maintenanceDetails;

  return (
    <div className={styles.Container} data-testid="AppUnderMaintenance">
      <div className={isAppDown ? styles.PageDownInnerContainer : styles.MaintenanceInnerContainer}>
        <div>
          <img
            alt="6sense logo"
            className={styles.EmptyStateImg}
            src="https://6si-static.s3.amazonaws.com/si_app_assets/Logo-Full.png"
          />
        </div>
        <div>
          <div className={styles.DescriptionTitle}>{applicableText.title}</div>
          <span className={styles.DescriptionText}>{applicableText.description}</span>
        </div>
      </div>
    </div>
  );
}
