import { EnrolmentState } from './enrolment';
import { MetaSchema } from './meta';

export type IqlState = 'error' | 'processing' | 'pending' | 'success';

export const leadJourneyStatuses = {
  error: { withErrors: true },
  import_pending: { sourceType: 'IntegrationQueuedLead', states: ['pending'] },
  bounce_risk: { withPotentialBounceWarnings: true },
  enrollment_pending: { sourceType: 'Enrolment', states: ['pending', 'planned', 'scheduled'] },
  enrolled: { sourceType: 'Enrolment', states: ['processed'] },
} as const;

export type LeadJourneyStatus = keyof typeof leadJourneyStatuses;

export function isLeadJourneyStatus(value: any): value is LeadJourneyStatus {
  return Object.keys(leadJourneyStatuses).includes(value);
}

type SourceType = 'Enrolment' | 'IntegrationQueuedLead';

type SummaryMetricDetail = {
  count: number;
  name: string;
};
type SummaryMetric = {
  name: string;
  status: LeadJourneyStatus | null;
  value: number;
  color?: 'warn' | 'error';
  tooltipDetails?: { type: 'text'; value: string } | { type: 'list'; value: SummaryMetricDetail[] };
};

export interface LeadJourneySchema {
  account: { id: number; name: string } | null;
  details: { displayText: string; errorDetails: string[]; key: string }[];
  id: string;
  lead: {
    email: string | null;
    firstName: string | null;
    id: number | null;
    lastName: string | null;
  };
  leadOwner: string | null;
  sourceId: string;
  sourceType: SourceType;
  status: LeadJourneyStatus;
  updatedAt: string;
}
export interface LeadJourneyApiSchema {
  attributes: {
    accountId: number | null;
    details: { displayText: string; errorDetails: { message: string }[]; key: string }[] | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    leadOwnerId: number | null;
    sourceType: SourceType;
    state: IqlState | EnrolmentState;
    updatedAt: string;
  };
  id: string;
  relationships: {
    account: { data: { id: number; type: 'accounts' } | null };
    lead: { data: { id: number; type: 'leads' } | null };
    leadOwner: { data: { id: number; type: 'sales_reps' } | null };
  };
  type: 'lead_enrolment_journeys';
}

export interface AccountIncludedSchema {
  attributes: { leadsCount: number; name: string };
  id: number;
  type: 'accounts';
}
export interface LeadOwnerIncludedSchema {
  attributes: { email: string; firstName: string; lastName: string };
  id: number;
  type: 'sales_reps';
}
export interface LeadJourneysApiSchema {
  data: LeadJourneyApiSchema[];
  included: (LeadOwnerIncludedSchema | AccountIncludedSchema)[];
  meta: MetaSchema;
}

export interface LeadJourneyMetricsApiSchema {
  errors: {
    enrolmentErrors: number;
    importErrors: number;
  };
  pendingEnrolments: number;
  pendingImports: number;
  successfulEnrolments: number;
  totalContacts: number;
  withPotentialBounceWarnings: {
    disposableEmail: number;
    freeEmailProvider: number;
    invalidEmailServer: number;
    roleBasedEmail: number;
    totalContacts: number;
  };
}
export interface LeadJourneyMetricsSchema {
  errors: SummaryMetric;
  pendingEnrolments: SummaryMetric;
  pendingImports: SummaryMetric;
  successfulEnrolments: SummaryMetric;
  totalContacts: SummaryMetric;
  withPotentialBounceWarnings: SummaryMetric;
}
