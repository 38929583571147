import { ErrorInfo } from 'react';
import { LogArgument, Payload } from 'rollbar';

function setChunkMarkerWithExpiry(key: string, value: boolean, timeToExpiry: number) {
  const item = {
    value,
    expiry: new Date().getTime() + timeToExpiry,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getChunkMarkerWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (itemString) {
    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;
    if (!isExpired) {
      return item.value;
    }
    localStorage.removeItem(key);
  }
  return null;
}

export function isChunkLoadError(errorMessage: string) {
  const chunkFailedMessage = /^Loading .*chunk .* failed./;
  return chunkFailedMessage.test(errorMessage);
}

export function handleErrorBoundary(error: Error, info: ErrorInfo) {
  if (isChunkLoadError(error.message) && !getChunkMarkerWithExpiry('chunk_failed_marker')) {
    setChunkMarkerWithExpiry('chunk_failed_marker', true, 60000);
    window.location.reload();
    return {};
  }
  return { error, info };
}

export function checkChunkLoadErrorActor(args: LogArgument[], payload: Payload) {
  const error = args[0] || null;
  // weird logic but i'll make it better
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    isChunkLoadError(error.message as string)
  ) {
    const client = payload?.client?.javascript;
    const screen = client?.screen;
    const browser = client?.browser;

    const isSuspiciousResolution = screen.width === 800 && screen.height === 600;
    const isLinux = !!browser?.includes('Linux');
    const isSuspiciousBrowser = !!(
      (browser?.includes('Chrome/116.0.0.0') && isSuspiciousResolution) ||
      browser?.includes('HeadlessChrome/130.0.0.0')
    );

    return isLinux && isSuspiciousBrowser;
  }

  return false;
}
