import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { DESIGN_SYSTEM_THEME, ThemeProvider } from '@saleswhale/barnacle/style';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ErrorFallback } from 'features/errorFallback/ErrorFallback';
import { handleErrorBoundary } from 'features/errorFallback/utils';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { FLAGSMITH_API_KEY } from './config/environment';
import { initializeMixpanel } from './initializers/analytics';
import initializePendo from './initializers/pendo';
import { persistOptions, queryClient } from './services/reactQuery';
import { rollbarConfig } from './services/rollbar';
import './index.scss';

initializeMixpanel();
initializePendo();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ThemeProvider theme={DESIGN_SYSTEM_THEME}>
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      <RollbarProvider config={rollbarConfig}>
        <FlagsmithProvider
          flagsmith={flagsmith}
          options={{
            environmentID: FLAGSMITH_API_KEY,
            cacheFlags: false,
            api: 'https://api.flagsmith.com/api/v1/',
          }}
        >
          <ErrorBoundary
            extra={(error, info) => handleErrorBoundary(error, info)}
            fallbackUI={({ error }) => <ErrorFallback _error={error} flagsmith={flagsmith} />}
          >
            <Router>
              <App />
            </Router>
          </ErrorBoundary>
        </FlagsmithProvider>
      </RollbarProvider>
      <ReactQueryDevtools position="bottom-right" />
    </PersistQueryClientProvider>
  </ThemeProvider>
);
